import * as React from 'react';
import { useNavigate } from "react-router-dom"
import { Card } from '../../components/Card';
import { Title } from '../../components/Typography';
import { useLoginContext } from '../../scripts/data';
import { Button, Grid } from '@mui/material';

export default function HomePage({routes, activeRoutes}) {
    const navigate = useNavigate();
    const lc = useLoginContext()
    

    return lc && <Grid container spacing={2} sx={{p: 2}}>

        <Grid item xs={12} md={4}>
        <Card title={"Welcome, " + lc.username + "!"}>
            {activeRoutes.map(el => 
                <Button key={el} variant="contained" sx={{mr: 2, my: 1}} onClick={() => {navigate(el)}}>
                    {routes[el].icon} <Title sx={{mx: 2}} color="text.primary.main">{routes[el].name}</Title>
                </Button>
            )}
        </Card>
        </Grid>
        
    </Grid>
}