import * as React from 'react';
import {
  AppBar,Box, Toolbar, Typography, IconButton, Drawer, Divider, List, ListItem, ListItemIcon, ListItemButton, ListItemText, useTheme, ListSubheader, Avatar
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BarChartIcon from '@mui/icons-material/BarChart';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import {Routes, Outlet, Route, BrowserRouter as Router, useLocation, useNavigate, Navigate} from 'react-router-dom';

import BotPage from './pages/bot/bot'
import OverridePage from './pages/overrides/overrides';
import SettingsPage from './pages/settings/settings';
import constants from './scripts/constants';
import { LoginContext, useLoginContext, useLoginStatus } from './scripts/data';

import SmartToyIcon from '@mui/icons-material/SmartToy';
import LoginPage from './pages/login/login';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { tryLogout } from './pages/login/login_utils';
import HomePage from './pages/home/home';

import { setListener, getLogin } from './pages/login/login_utils';

let routes = {
  "/": {name: "Home", icon: <HomeIcon/>, page: <HomePage/>},
  "/bot/simulation": {name: "Bot Simulation", icon: <SmartToyIcon/>, page: <BotPage actual={false}/>, section: "Bot"},
  "/bot/actual": {name: "Bot Actual", icon: <SmartToyIcon/>, page: <BotPage actual={true}/>},
  "/overrides": {name: "Overrides", icon: <ToggleOnIcon/>, page: <OverridePage/>},
  "/settings": {name: "Settings", icon: <SettingsIcon/>, page: <SettingsPage/>},
}

let authorized_paths = { 
  "admin": ["/bot/simulation", "/bot/actual", "/overrides", "/settings"],
  "smart": []
}

function UserAvatar() {
  const navigate = useNavigate();
  const theme = useTheme();

  const onLogout = async () => {
    const status = await tryLogout();
    if (status == true) {
      navigate('/login'); 
    }
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>
            <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main, width: 36, height: 36 }} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <List sx={{width: 200}}>
          
              <ListItem disablePadding>
                <ListItemButton onClick={onLogout}>
                  <ListItemIcon sx={{mr: -2, color: theme.palette.primary.main}}><PowerSettingsNewIcon/></ListItemIcon>
                  <ListItemText primary={"Uitloggen"} sx={{color: theme.palette.primary.main}}/>
                </ListItemButton>
              </ListItem>
          </List>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

function Layout({drawer}) {
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const navigate = useNavigate();

    const login = useLoginContext();

    return <React.Fragment><AppBar
      position="static"
      elevation={0}
      sx={{ bgcolor: "white", color: (theme) => theme.palette.primary.main, borderBottom: (theme) => "1px solid " + theme.palette.background.border }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
      <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
                edge="start"
                sx={{
                  marginRight: 1
                }}
            >
            <MenuIcon />
          </IconButton>
        {routes[location.pathname] ? routes[location.pathname]["icon"] : null}
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1, fontWeight: "700", pl: 1 }}>
          {routes[location.pathname] ? routes[location.pathname].name.toUpperCase() : null}
        </Typography>
        {/*<nav>
          {location.pathname == "/club" ? null : <Button href="club" color="inherit">Club zoeken</Button>}
          {location.pathname == "/" ? null : <Button href="/" color="inherit">Speler zoeken</Button>}
        </nav>*/}
        <UserAvatar/>
      </Toolbar>
    </AppBar>
    <Drawer
        sx={{
          width: 200,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 200,
            boxSizing: 'border-box',
          },
        }}
        position="persistent"
        anchor="left"
        open={open}
      >
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon color={theme.palette.text.primary_bold} />
          </IconButton>
        </div>
        <Divider />
        <List>
        <ListItem disablePadding>
              <ListItemButton onClick={() => {navigate("/"); setOpen(false)}}>
                <ListItemIcon sx={{mr: -2, color: location.pathname === "/" ? theme.palette.primary.main : theme.palette.text.primary_bold}}>{routes["/"]["icon"]}</ListItemIcon>
                <ListItemText primary={routes["/"]["name"]} sx={{color: location.pathname === "/" ? theme.palette.primary.main : theme.palette.text.primary_bold}}/>
              </ListItemButton>
            </ListItem>

       
          {login && login.role && authorized_paths[login.role].map((route, index) => (
            <React.Fragment key={route}>
               {routes[route].section ? <ListSubheader sx={{lineHeight: 2, mt: 2}}>{routes[route].section}</ListSubheader> : null}
               <ListItem disablePadding>
                <ListItemButton onClick={() => {navigate(route); setOpen(false)}}>
                  <ListItemIcon sx={{mr: -2, color: location.pathname === route ? theme.palette.primary.main : theme.palette.text.primary_bold}}>{routes[route]["icon"]}</ListItemIcon>
                  <ListItemText primary={routes[route]["name"]} sx={{color: location.pathname === route ? theme.palette.primary.main : theme.palette.text.primary_bold}}/>
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}

          <Divider/>
          
        </List>
      </Drawer>
      
      {/*<Box sx={{display: "flex", justifyContent: "center"}}>
      <Alert severity="warning" sx={{mb: 5, mt: 2, mx: 2, fontWeight: "bold", display: "flex", justifyContent: "center"}} variant="filled">Geen batterij geconnecteerd!</Alert>
          </Box>*/}

    <Outlet/>

    </React.Fragment>
}

export default function Navigation () {
    const [login, setLogin] = React.useState(getLogin())

    React.useEffect(() => {setListener(setLogin)}, []);

    return (<LoginContext.Provider value={login}>
      <Router>
        <CustomRoutes login={login} setLogin={setLogin} />
      </Router>
      </LoginContext.Provider>
    );
}

function CustomRoutes({login}) {
  return <Routes>
            
    <Route path="/" element={<Layout drawer/>}>
      <Route index element={<PrivateElement element={<HomePage routes={routes} activeRoutes={login ? authorized_paths[login.role] : []}/>} />} />
      {
        Object.keys(routes).filter(el => el !== "/").map(path => {
            return <Route key={path} path={path} element={<PrivateElement element={routes[path].page}/>} />
        })
      }
       <Route path={"/botdebug"} element={<PrivateElement element={<BotPage debug={true} />} />} />
    </Route>
    <Route path="/login">
      <Route index element={<LoginPage/>}/>
    </Route>
</Routes>
}

const PrivateElement = ({element}) => {
  const loginStatus = useLoginStatus();

  return loginStatus > 0 ? element : <Navigate to="/login" replace />
}