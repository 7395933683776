import { ThemeProvider, createTheme, CssBaseline, ThemeOptions } from "@mui/material";

import Navigation from "./Navigation"

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#39829C',
      light: '#6398ab'
    },
    secondary: {
      main: '#64D59A',
      light: '#8fdbb3'
    },
    tertiary: {
      main: '#d9150b',
    },
    text: {
      //primary: "white",
      primary: '#2F4F4F',
    },
    background: {
      default: '#F7F8FA',//'#0091ea',
      border: '#EEEEEE'
    }
  },
};

function App() {

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Navigation/>
    </ThemeProvider>  
  );
}

export default App;