import { Typography } from "@mui/material";

export function Title(props) {
    return <Typography fontWeight="700" textTransform="uppercase" color={(theme) => theme.palette.primary.main} fontSize={18} {...props}>{props.children}</Typography>
}

export function SubTitle(props) {
    return <Typography fontWeight="700" textTransform="uppercase" color={(theme) => theme.palette.primary.main} fontSize={16} {...props}>{props.children}</Typography>
}

export function Text(props) {
    return <Typography color="darkslategrey" fontSize={16} {...props}>{props.children}</Typography>
}