import { Title } from "./Typography"
import { SubTitle } from "./Typography"
import { Box } from "@mui/material"
import * as React from 'react';

export function Metric({name, value}) {
    return <Center mx={2} py={0.5}>
            <SubTitle color="text.primary" fontSize={12}>{name}</SubTitle>
            <Title fontSize={16} textTransform="default">{value}</Title>
        </Center>
}

export function Center(props) {
    return <Box {...props} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
        {props.children}
    </Box>
}

export function ColoredChip({backgroundColor, children, fontSize}) {
    return <span style={{color: "white", backgroundColor: backgroundColor, borderRadius: 20, padding: '5px 6px', fontSize: fontSize ? fontSize : 8, fontWeight: "bold", marginRight: 5}}>{children}</span>
}

export function AutoFetch({fetch, frequency}) {
    const firstRender = React.useRef(true);

    React.useEffect(() => {
        const interval = setInterval(fetch, frequency);
        return () => clearInterval(interval);
    }, [frequency]);

    React.useEffect(() => {
        if (firstRender.current == true) {
            fetch()
            firstRender.current = false;
        }
    }, []);

    return null;
}