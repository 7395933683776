import React from "react";

export const DataContext = React.createContext({});
export function useData() {
    const context = React.useContext(DataContext);

    return context;
}

export const LoginContext = React.createContext({role: null});
export function useLoginContext() {
    const context = React.useContext(LoginContext);

    return context;
}

export function useLoginStatus() {
    const lc = useLoginContext();

    if (!lc) {
        return 0;
    }

    if (lc.expiry >= (new Date()).getTime()/1000) {
        return 1; // logged in and access token valid
    }

    if (lc.expiry_refresh && lc.expiry_refresh >= (new Date()).getTime()/1000) {
        return 2; // logged in, but has to be refreshed
    }

    return 0;
}