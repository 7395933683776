import constants from "./constants";

function subscribeToWebPush(subscription) {
  return fetch(constants["HOST"] + "/api/add-subscription", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscription_json: JSON.stringify(subscription)
    })
  });

}

function unsubscribeFromWebPush(subscription) {
  return fetch(constants["HOST"] + "/api/remove-subscription", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscription_json: JSON.stringify(subscription)
    })
  });

}

class WebPushService {
    static hasPermission() {
      return Notification.permission === "granted";
    }
  
    static async requestPermission() {
      return await Notification.requestPermission();
    }
  
    static async getSubscription() {
      return await navigator.serviceWorker.ready.then(async (registration) => {
        return await registration.pushManager.getSubscription();
      });
    }
  
    static async subscribe() {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
      });
      subscribeToWebPush(subscription); // server

      return subscription;
    }
  
    static async unsubscribe() {
      const subscription = await this.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        unsubscribeFromWebPush(subscription);
      }
      return subscription;
    }
  }
  
  export default WebPushService;