import React from 'react';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Title,
  Tooltip,
  Filler,
  BarElement,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Box, Typography, useTheme } from '@mui/material';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  TimeScale,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export function alphaColor(color, alpha) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  return "rgba(" + R + "," + G + "," + B + "," + alpha + ")"
}

export function DataChart({data, options}) {
    return <Line data={data} width={"100%"} options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
              position: 'bottom',
              display: false,
            },
        },
        ...options
    }} />
}

export const getBarTimeChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        legend: {
          position: 'bottom',
          display: true,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                tooltipFormat:'dd/MM/yyyy HH:mm',
                displayFormats: {
                    hour: 'HH:mm'
                },
                unit: "hour",
                stepSize: 1,
            },
            stacked: true,
            grid: {
                offset: false,
            }
        },
        y: {
            stacked: true,
        },
        
    },
})

export function BarTimeChart({data, options}) {
    return <Bar data={data} width={"100%"} options={options ? options : getBarTimeChartOptions()} />
}

export const getTimeChartOptions = () => ({
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
              position: 'bottom',
              display: true,
            },
        }, 
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat:'dd/MM/yyyy HH:mm',
                    displayFormats: {
                        hour: 'HH:mm',
                        minute: 'HH:mm'
                    },
                    unit: "hour",
                    unitStepSize: 1,
                },
            }
        }
    })

export function TimeChart({data, options}) {
    return <Line data={data} width={"100%"} options={options ? options : getTimeChartOptions()} />
}

export function TestChart() {
  //const chartData = useChartData();
  const theme = useTheme();
  const data = {
    labels: labels,//chartData["historical"].map(el => 1000*el.utctime),
    datasets: [
      {
        fill: true,
        label: 'Profit',
        data: labels.map((el, index) => index), //chartData["historical"].map(el => el.soc === "nan" ? null : el.soc),
        borderWidth: 2,
        borderColor: theme.palette.primary.main,//'white',
        backgroundColor: alphaColor(theme.palette.primary.main, 0.6),//'rgba(255,255,255,0.4)',
        cubicInterpolationMode: 'monotone',
        pointStyle: false,
      },
    ],
  };

  return <DataChart data={data} />
}