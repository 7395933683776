import { SettingsPowerRounded } from "@mui/icons-material";
import { Container, Typography, Box, Divider, TextField, Snackbar, Chip, Grid, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import * as React from 'react'
import constants from '../../scripts/constants'
import { DataContext, useData, useLoginData } from "../../scripts/data";
import {Card} from "../../components/Card";
import { Text, Title, SubTitle } from "../../components/Typography";
import { Metric, ColoredChip, AutoFetch } from "../../components/Custom";

import CircleIcon from '@mui/icons-material/Circle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ImbalancePricesChart, InputContributionsChart, IntradayPricesChart, ForecastedSIPChart, PositionChart, AlphaChart, ProfitChart, ForecastedProbabilitiesChart, ForecastChart, CurrentSystemImbalanceChart, InputValuesChart } from "./botcharts";

import { authfetch } from "../login/login_utils";
import { useLocation } from "react-router-dom";

async function fetchBotData(setData, debug, tag, variant) {
    try {
        const f = await authfetch(constants["HOST"] + "/api/bot?debug=" + (debug ? "1" : "0") + "&tag=" + tag + (variant ? "&variant=" + variant : ""))
        const r = await f.json()

        setData(r);
    } catch (e) {
        console.error(e)
    }
}

export default function BotPage({debug, actual}) {

    const [data, setData] = React.useState(null);
    const [input, setInput] = React.useState("penetration_so");
    const location = useLocation()

    const [range, setRange] = React.useState(1);
    const [tag, setTag] = React.useState("LTS15");
    const tagRef = React.useRef(tag);
    const variantRef = React.useRef(undefined);

    const fetch = () => {
        fetchBotData(setData, debug, actual ? "ACTUAL" : tagRef.current, variantRef.current)
    }

    const variants = {
        "LTS0": "simple",
        "LTS": "simple",
        "EXPS": "simple"
    }

    React.useEffect(() => {
        tagRef.current = tag
        variantRef.current = variants[tag]
        fetch()
    }, [tag]);

    const theme = useTheme();

    return <>
        <AutoFetch key={location.pathname} fetch={() => fetch()} frequency={30000}/> 
        {data && <DataContext.Provider value={data}>
            {actual ? null : <Box display="flex" flexDirection="row" alignItems="center" pt={2} px={2} width="100%" flexWrap="wrap">
                <SelectChip name="LTE0" tag={tag} setTag={setTag} value="LTE0"/>
                <SelectChip name="LTE5" tag={tag} setTag={setTag} value="LTE5"/>
                <SelectChip name="LTE15" tag={tag} setTag={setTag} value="LTE15" bold/>
                <SelectChip name="LTE" tag={tag} setTag={setTag} value="LTE"/>
                <SelectChip name="LTE-SIP" tag={tag} setTag={setTag} value="LTE-SIP"/>
                <SelectChip name="EXP" tag={tag} setTag={setTag} value="EXP"/>
                <SelectChip name="EXP25" tag={tag} setTag={setTag} value="EXP25"/>
                <SelectChip name="EXP50 - Alt" tag={tag} setTag={setTag} value="EXP50"/>
                <SelectChip name="CVAR" tag={tag} setTag={setTag} value="CVAR"/>
                <SelectChip name="Kelly" tag={tag} setTag={setTag} value="KELLY"/>
                <SelectChip name="CVAR Kelly" tag={tag} setTag={setTag} value="CVARKELLY"/>
                <SelectChip name="LTS0" tag={tag} setTag={setTag} value="LTS0"/>
                <SelectChip name="LTS5" tag={tag} setTag={setTag} value="LTS5"/>
                <SelectChip name="LTS15" tag={tag} setTag={setTag} value="LTS15"/>
                <SelectChip name="LTS" tag={tag} setTag={setTag} value="LTS"/>
                <SelectChip name="EXPS" tag={tag} setTag={setTag} value="EXPS"/>
                <SelectChip name="LTS-SIP" tag={tag} setTag={setTag} value="LTS-SIP"/>
            </Box>}

        <Grid container spacing={2} px={2} py={2}>
        <Grid item md={4} xs={12}>
            <Card title="Quarter" minHeight={(theme) => 'calc(200px - ' + theme.spacing() + ')'} elevation={0} sx={{display: "flex", alignItems: "center", flexDirection: "column"}} >
                    <Grid container>
                        <GridMetric name="Total Profit" value={"€" + Math.round(100*data["overview"]["profit_qtr"])/100} />
                        <GridMetric name="Total Trades" value={data["overview"]["trades_qtr"]} />
                        <GridMetric name="PPT" value={"€" + Math.round(100*data["overview"]["profit_per_trade_qtr"])/100} />
                        <GridMetric name="Profit Today" value={"€" + Math.round(100*data["overview"]["profit_today_qtr"])/100} />
                        <GridMetric name="Trades Today" value={data["overview"]["trades_today_qtr"]} />
                        <GridMetric name="PPT Today" value={"€" + Math.round(100*data["overview"]["profit_per_trade_today_qtr"])/100} />
                    </Grid>
            </Card>
            <Card title="Hour" minHeight={(theme) => 'calc(200px - ' + theme.spacing() + ')'} mt={2} elevation={0} sx={{display: "flex", alignItems: "center", flexDirection: "column"}} >
                    <Grid container>
                        <GridMetric name="Total Profit" value={"€" + Math.round(100*data["overview"]["profit_hr"])/100} />
                        <GridMetric name="Total Trades" value={data["overview"]["trades_hr"]} />
                        <GridMetric name="PPT" value={"€" + Math.round(100*data["overview"]["profit_per_trade_hr"])/100} />
                        <GridMetric name="Profit Today" value={"€" + Math.round(100*data["overview"]["profit_today_hr"])/100} />
                        <GridMetric name="Trades Today" value={data["overview"]["trades_today_hr"]} />
                        <GridMetric name="PPT Today" value={"€" + Math.round(100*data["overview"]["profit_per_trade_today_hr"])/100} />
                    </Grid>
            </Card>
        </Grid>
        <Grid item md={8} xs={12}>
            <Card height={400} title="Positions">
                <Box height={320}>
                    <PositionChart/>
                </Box>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card height={400} title="System Imbalance">
                <Box height={300}>
                <CurrentSystemImbalanceChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={3} xs={12}>
            <Card height={400} title="Imbalance Feed">
                <ImbalanceFeedTable/>
            </Card>
        </Grid>
        <Grid item md={3} xs={12}>
            <Card height={400} title = "Recent Trades">
                <RecentTradesTable/>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card height={400} title="Imbalance Price">
                <Box height={320}>
                <ImbalancePricesChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card height={400}>
                <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" pb={2}>
                    <Title mr={2}>Profit</Title>
                    <Chip sx={{mx: 0.5}} label="Today"  onClick={() => setRange(1)}/>
                    <Chip sx={{mx: 0.5}} label="This Week" onClick={() => setRange((new Date()).getDay())} />
                    <Chip sx={{mx: 0.5}} label="This Month" onClick={() => setRange((new Date()).getDate())} />
                    <Chip sx={{mx: 0.5}} label="This Quarter" onClick={() => setRange(90)} />
                    <Chip sx={{mx: 0.5}} label="All" onClick={() => setRange(1000)} />
                </Box>
                
                <Box height={{xs: 280, md: 320}}>
                
                <ProfitChart range={range} />
                </Box>
            </Card>
        </Grid>
        <Grid item md={12} xs={12}>
            <Card height={500} title="Forecast">
                <Box height={420}>
                <ForecastChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={12} xs={12}>
            <Card height={500} title="Forecasted Probabilities">
                <Box height={420}>
                <ForecastedProbabilitiesChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={12} xs={12}>
            <Card height={500} title="Forecasted System Imbalance">
                <Box height={420}>
                <ForecastedSIPChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card height={300} title="Intraday Price">
                <Box height={220}>
                    <IntradayPricesChart />
                </Box>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card height={300} title="Intraday Price">
                <Box height={220}>
                    <IntradayPricesChart type={"hr"} />
                </Box>
            </Card>
        </Grid>

        {"running_cost_long_qtr" in data.overview || "running_cost_short_hr" in data.overview ? [
        <Grid item md={6} xs={12}>
            <Card height={300} title="Alpha QTR">
                <Box height={220}>
                    {"running_cost_long_qtr" in data.overview ? <AlphaChart type={"qtr"} /> : null}
                </Box>
            </Card>
        </Grid>,
        <Grid item md={6} xs={12}>
            <Card height={300} title="Alpha HR">
                <Box height={220}>
                {"running_cost_long_hr" in data.overview ? <AlphaChart type={"hr"} /> : null}
                </Box>
            </Card>
        </Grid>] : null
        }
        
        {/*<Grid item md={6} xs={12}>
            <Card height={300} title="Probabilities">
                <Box height={220}>
                <ProbabilitiesChart />
                </Box>
            </Card>
        </Grid>*/}      
        
        <Grid item md={12} xs={12}>
            <Card height={500} title="Input Contributions">
                <Box height={420}>
                <InputContributionsChart debug={debug} />
                </Box>
            </Card>
        </Grid>
        <Grid item md={12} xs={12}>
            <Card height={500}>
                <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" pb={2}>
                    <Title mr={2}>Input Values</Title>
                    <InputValuesSelect input={input} setInput={setInput} />
                </Box>

                <Box height={380}>
                <InputValuesChart input={input} />
                </Box>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card title="Trades - QH">
                <TradesTable data={data["trades_qtr"]}/>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card title="Trades - H">
                <TradesTable data={data["trades_hr"]}/>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card title="Order book - QH">
                <OrderbookTable data={data["order_book_qtr"]}/>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card title="Order book - H">
                <OrderbookTable data={data["order_book_hr"]}/>
            </Card>
        </Grid>
    </Grid>
    </DataContext.Provider>}</>
}

function SelectChip({tag, setTag, name, value, bold}) {
    return <Chip sx={{mx: 0.5, my: 0.5, fontWeight: bold ? "bold" : "normal"}} label={name} color={tag == value ? "primary" : "default"}  onClick={() => setTag(value)}/>
}

function padZero(v) {
    return (v.toString().length == 1 ? "0" : "") + v;
}

function TradesTable({data}) {
    return <TableContainer component={null}>
    <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>POSITION</SubTitle></TableCell>
          <TableCell align="right"><SubTitle fontSize={12}>ID PRICE</SubTitle></TableCell>
          {/*<TableCell align="right"><SubTitle fontSize={12}>ESTIMATED PRICE</SubTitle></TableCell>*/}
          <TableCell align="right"><SubTitle fontSize={12}>IMBALANCE PRICE</SubTitle></TableCell>
          {/*<TableCell align="right"><SubTitle fontSize={12}>PROBABILITY</SubTitle></TableCell>*/}
          <TableCell align="right"><SubTitle fontSize={12}>PROFIT</SubTitle></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {data.map((row) => {
            const time = new Date(1000*row.utctime);
            
            return <TableRow
              key={row.utctime}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{fontWeight: "bold"}}>
                {padZero(time.getHours()) + ":" + padZero(time.getMinutes())}
              </TableCell>
              <TableCell align="center"><span style={{color: "white", backgroundColor: row.buysell === "BUY" ? "limegreen" : "red", borderRadius: 20, padding: '5px 6px', fontSize: 10, fontWeight: "bold"}}>{(row.buysell === "BUY" ? "L" : "S") + " \u2022 " + Math.round(100*row.volume)/100}</span></TableCell>
              <TableCell align="right">{"€" + Math.round(100*row.price)/100}</TableCell>
              {/*<TableCell align="right">{row.estimated_price}</TableCell>*/}
              <TableCell align="right">{row.imb_price === "nan" ? "" : ("€" + row.imb_price)}</TableCell>
              {/*<TableCell align="right">{Math.round(100*row.ppos)}%</TableCell>*/}
              <TableCell align="right" style={{color: row.profit > 0 ? "limegreen" : "red", fontWeight: "bold"}}>{row.profit === "nan" ? "" : "€" + Math.round(100*row.profit)/100}</TableCell>
            </TableRow>
        })}
        </TableBody>
    </Table>
  </TableContainer>
}

function OrderbookTable({data}) {
    return <TableContainer component={null}>
    <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right"><SubTitle fontSize={12}>-25MW</SubTitle></TableCell>
          {/*<TableCell align="right"><SubTitle fontSize={12}>ESTIMATED PRICE</SubTitle></TableCell>*/}
          <TableCell align="right"><SubTitle fontSize={12}>-10MW</SubTitle></TableCell>
          {/*<TableCell align="right"><SubTitle fontSize={12}>PROBABILITY</SubTitle></TableCell>*/}
          <TableCell align="right" sx={{borderRight: 'solid 1px lightgrey'}}><SubTitle fontSize={12}>-1MW</SubTitle></TableCell>
          <TableCell align="right" sx={{borderRight: 'solid 1px lightgrey'}}><SubTitle fontSize={12}>EP</SubTitle></TableCell>
          <TableCell align="left"><SubTitle fontSize={12}>1MW</SubTitle></TableCell>
          <TableCell align="left"><SubTitle fontSize={12}>10MW</SubTitle></TableCell>
          <TableCell align="left"><SubTitle fontSize={12}>25MW</SubTitle></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {data.map((row) => {
            const time = new Date(1000*row.utctime);
            
            return <TableRow
              key={row.utctime}
              sx={{ opacity: new Date() > time ? 0.5 : 1,'td, th': {borderBottom: time.getMinutes() == 45 ? "solid 1px lightgrey" : 'solid 1px rgb(240,240,240)'}, '&:last-child td, &:last-child th': { borderBottom: 0 } }}
            >
              <TableCell sx={{padding: "0px 6px"}} component="th" scope="row" style={{fontWeight: "bold"}}>
                {padZero(time.getHours()) + ":" + padZero(time.getMinutes())}
              </TableCell>
                <OrderBookCell bid price={row["-25mw"]} estimated_price={row["estimated_price"]}/>
                <OrderBookCell bid price={row["-10mw"]} estimated_price={row["estimated_price"]}/>
                <OrderBookCell bid borderRight price={row["-1mw"]} estimated_price={row["estimated_price"]}/>
                <TableCell sx={{padding: "0px 6px", borderRight: 'solid 1px lightgrey'}} align="center">{row["estimated_price"] !== "nan" ? "€" + Math.round(100*row["estimated_price"])/100 : ""}</TableCell>
                <OrderBookCell price={row["1mw"]} estimated_price={row["estimated_price"]}/>
                <OrderBookCell price={row["10mw"]} estimated_price={row["estimated_price"]}/>
                <OrderBookCell price={row["25mw"]} estimated_price={row["estimated_price"]}/>
            </TableRow>
        })}
        </TableBody>
    </Table>
  </TableContainer>
}

function InputValuesSelect({input, setInput}) {
    const bd = useData();

    const values = bd["input_values"].length > 0 ? Object.keys(bd["input_values"][0]).filter(t => t !== 'utctime') : [];

    const handleChange = (event) => {
      setInput(event.target.value);
    };
  
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Input</InputLabel>
        <Select
          value={input}
          label="Input"
          onChange={handleChange}
        >
            {values.map(val => <MenuItem value={val}>{val}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }

function OrderBookCell({price, estimated_price, borderRight, bid}) {
    const active = (bid && price > estimated_price) || (!bid && price < estimated_price)

    const theme = useTheme();

    return <TableCell sx={{
            padding: "0px 6px", 
            borderRight: borderRight ? 'solid 1px lightgrey' : undefined,
            color: active ? theme.palette.primary.main : undefined,
            fontWeight: active ? "bold" : "normal"
        }} align={bid ? "right" : "left"}>
        {price !== "nan" ? "€" + Math.round(100*price)/100 : ""}
        </TableCell>

}

function GridMetric(props) {
    return <Grid xs={6} md={4} item sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Metric {...props}/>
    </Grid>
}

function ImbalanceFeedTable() {
    const bd = useData();

    return <TableContainer component={(props) => <Box height={300} {...props}/>}>
    <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="center"><SubTitle fontSize={12}>TIME</SubTitle></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>IMBALANCE</SubTitle></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>MDP</SubTitle></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>MIP</SubTitle></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {bd["imb_data"].toReversed().map((row) => {
            const time = new Date(1000*row.utctime);

            return <TableRow
              key={row.utctime}
              sx={{ 
                borderBottom: row.utctime % 900 == 0 ? 'dashed 2px' : 1,
                '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row">
                {padZero(time.getHours()) + ":" + padZero(time.getMinutes())}
              </TableCell>
              <TableCell align="center" component="th" scope="row" fontWeight="bold">
                {row.systemimbalance}
              </TableCell>
              <TableCell align="center" component="th" scope="row" sx={{fontWeight: row.systemimbalance > 0 ? "bold" : "regular"}}>
                €{row.systemimbalance > 0 ? row.imb_price : row.mdp}
              </TableCell>
              <TableCell align="center" component="th" scope="row" sx={{fontWeight: row.systemimbalance < 0 ? "bold" : "regular"}}>
                €{row.systemimbalance < 0 ? row.imb_price : row.mip}
              </TableCell>

            </TableRow>
        })}
        </TableBody>
    </Table>
  </TableContainer>
}

function RecentTradesTable() {
    const bd = useData();

    const fromts = (new Date()).getTime() / 1000 - 3600
    const tots = (new Date()).getTime() / 1000 + 3600

    var recentTradesCombined = {}

    bd["trades_qtr"].filter(row => row.utctime >= fromts && row.utctime <= tots).forEach(item => {
        recentTradesCombined[item.utctime] = {
            "qtr": item,
            "hr": {}
        }
    })

    bd["trades_hr"].filter(row => row.utctime >= fromts && row.utctime <= tots).forEach(item => {
        if (item.utctime in recentTradesCombined) {
            recentTradesCombined[item.utctime].hr = item
        } else {
            recentTradesCombined[item.utctime] = {
                "hr": item,
                "qtr": {}
            }
        }
    })

    return <TableContainer component={null}>
    <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="center"><SubTitle fontSize={12}>TIME</SubTitle></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>QTR</SubTitle></TableCell>
          <TableCell align="center"><SubTitle fontSize={12}>HR</SubTitle></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {Object.keys(recentTradesCombined).map((key) => {
            const time = new Date(1000*key);
            
            const row = recentTradesCombined[key];
            const row_qtr = row.qtr;
            const row_hr = row.hr;

            return <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row" style={{fontWeight: "bold"}}>
                {padZero(time.getHours()) + ":" + padZero(time.getMinutes())}
              </TableCell>
                <TableCell align="center">
                    <RecentTradesTablePosition row={row_qtr}/>
                </TableCell>
                <TableCell align="center">
                <RecentTradesTablePosition row={row_hr}/>    
                </TableCell>

            </TableRow>
        })}
        </TableBody>
    </Table>
  </TableContainer>;
}

function RecentTradesTablePosition({row}) {
    const showProfit = row.profit !== "nan"
    const theme = useTheme();

    return row.volume ? <Box>
        <ColoredChip backgroundColor={theme.palette.primary.main}>{(row.buysell === "BUY" ? "L" : "S") + Math.round(100*row.volume)/100}</ColoredChip>
        {!showProfit ? null : <ColoredChip backgroundColor={row.profit > 0 ? "limegreen" : "red"}>{"€" + Math.round(100*row.profit)/100}</ColoredChip>}
        </Box> : null
}

