import { SettingsPowerRounded } from "@mui/icons-material";
import { Container, Typography, Box, Button, TextField, Snackbar, Alert, Checkbox, FormControlLabel } from "@mui/material";
import * as React from 'react'
import { Card } from "../../components/Card";
import constants from '../../scripts/constants'
import { useNavigate } from "react-router-dom";
import { tryLogin } from "./login_utils";

export default function LoginPage() {

    const username = React.useRef();
    const password = React.useRef();
    const keepLoggedIn = React.useRef();

    const navigate = useNavigate();

    const onLogin = async () => {
        const status = await tryLogin(username.current.value, password.current.value, keepLoggedIn.current.checked)
        if (status == true) {
            navigate('/')
        }
        
    }

    return <Container maxWidth={"xs"} sx={{height: "100vh", display: "flex", justifyContent: "center", flexDirection: "column"}}>
        <Card title="Login" sx={{display: "flex", alignItems: "center", flexDirection: "column", mt: -10}}>
            <TextField inputRef={username} variant="outlined" placeholder="Username" sx={{width: "100%", my: 1}}/>
            <TextField inputRef={password} variant="outlined" placeholder="Password" type="password" sx={{width: "100%", my: 1}}/>

            <FormControlLabel control={<Checkbox defaultChecked inputRef={keepLoggedIn} />} label="Keep me logged in" sx={{mt: 1}} />

            <Button sx={{mt: 2, width: "100%"}} variant="contained" onClick={onLogin}>Login</Button>
        </Card>
    </Container>
}