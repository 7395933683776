import * as React from 'react'
import { Box, Typography, Button } from "@mui/material";
import { authfetch } from "../login/login_utils";
import constants from '../../scripts/constants'

async function fetchOverrides(setData) {
    try {
        const f = await authfetch(constants["HOST"] + "/api/overrides")
        const r = await f.json()

        setData(r);
    } catch (e) {
        console.error(e)
    }
}

async function setOverride(action, setData) {
    try {
        const f = await authfetch(constants["HOST"] + "/api/set_override?action=" + action)
        const r = await f.json()

        setData(r);
    } catch (e) {
        console.error(e)
    }
}

async function clearOverrides(setData) {
    try {
        const f = await authfetch(constants["HOST"] + "/api/clear_overrides");
        const r = await f.json()

        setData(r);
    } catch (e) {
        console.error(e)
    }
}

export default function OverridePage() {
    const [overrides, setOverrides] = React.useState([]);

    React.useEffect(() => {fetchOverrides(setOverrides);}, [])

    return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="h5" sx={{textAlign: "center", width: "100%", mt: 2}}>
                Active overrides
            </Typography>

            {overrides.map(el => <Box sx={{textAlign: "center"}} key={el.creationdate}>
                {new Date(1000 * el.from_utc).toLocaleString()} - {new Date(1000 * el.to_utc).toLocaleString()} {el.action}
            </Box>)}

            <Button variant='contained' onClick={() => setOverride("DISABLE", setOverrides)} sx={{mt: 5, p: 1}}>
                send disable
            </Button>
            <Button variant='contained' onClick={() => clearOverrides(setOverrides)} sx={{mt: 1, p: 1}}>
                clear overrides
            </Button>

        </Box>
}