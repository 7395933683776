import { useData } from "../../scripts/data";
import { Box, Typography, useTheme } from '@mui/material';
import { alphaColor, DataChart, TimeChart, BarTimeChart, getBarTimeChartOptions, getTimeChartOptions } from "../../components/Charts";
import { Line } from "react-chartjs-2";

export function ProfitChart({range}) {
    const bd = useData();
    const theme = useTheme();

    const now = new Date()
    const today = new Date(now.getFullYear(),now.getMonth(),now.getDate()).getTime() / 1000

    const cpqtr = bd["cum_profit_qtr"].filter(el => el.utctime >= today - (range-1) * 24*3600)
    const cphr = bd["cum_profit_hr"].filter(el => el.utctime >= today - (range-1) * 24*3600)

    const profit_qtr = cpqtr.length > 0 ? cpqtr[cpqtr.length - 1].cumprofit - cpqtr[0].cumprofit + cpqtr[0].profit : 0;
    const profit_hr = cphr.length > 0 ? cphr[cphr.length - 1].cumprofit - cphr[0].cumprofit + cphr[0].profit : 0;

    const profit_qtr_str = Math.abs(profit_qtr) > 10000 ? Math.round(profit_qtr / 100) / 10 + 'k' : Math.round(profit_qtr);
    const profit_hr_str = Math.abs(profit_hr) > 10000 ? Math.round(profit_hr / 100) / 10 + 'k' : Math.round(profit_hr);

    const data = {
        labels: cpqtr.map(el => new Date(1000*el.utctime)),
        datasets: [
        {
            fill: true,
            label: 'QTR: €' + profit_qtr_str,
            data: cpqtr.map(el => el.cumprofit === "nan" ? null : el.cumprofit - cpqtr[0].cumprofit + cpqtr[0].profit),
            borderWidth: 2,
            borderColor: theme.palette.primary.main,//'white',
            backgroundColor: alphaColor(theme.palette.primary.main, 0.05),//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
        },
        {
            fill: true,
            label: 'HR: €' + profit_hr_str,
            data: cphr.map(el => {return {
                x: new Date(1000*el.utctime), 
                y: el.cumprofit === "nan" ? null : el.cumprofit  - cphr[0].cumprofit + cphr[0].profit
            }}),
            borderWidth: 2,
            borderColor: theme.palette.secondary.main,//'white',
            backgroundColor: alphaColor(theme.palette.secondary.main, 0.05),//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
        },
        ],
    };

    const options = getTimeChartOptions()
    options["scales"]["x"]["time"]["unit"] = range == 1 ? "hour" : (range < 20 ? "day" : "week")
    options["interaction"]["mode"] = 'nearest';

    return <TimeChart data={data} options={options} />
}

export function PositionChart() {
    const bd = useData();
    const theme = useTheme();
    const data = {
        labels: bd["trades_qtr"].map(el => new Date(1000*el.utctime)),
        datasets: [
        {
            fill: true,
            label: 'Position QTR',
            data: bd["trades_qtr"].map(el => el.buysell === "BUY" ? el.volume : -el.volume),
            backgroundColor: bd["trades_qtr"].map(el => new Date(1000 * el.utctime) < new Date() ? theme.palette.primary.main : theme.palette.primary.light),//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 2,
        },
        {
            fill: true,
            label: 'Position HR',
            data: bd["trades_hr"].map(el => {return {
                x: new Date(1000*el.utctime), 
                y: el.buysell === "BUY" ? el.volume : -el.volume
            }}),
            backgroundColor: bd["trades_hr"].map(el => new Date(1000 * el.utctime) < new Date() ? theme.palette.secondary.main : theme.palette.secondary.light),//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 1,
        },
        ],
    };

    const options = getBarTimeChartOptions();
    //options["scales"]["y"]["min"] = -2.5;
    //options["scales"]["y"]["max"] = 2.5;

    return <BarTimeChart data={data} options={options} />
}

function groupEstimatedPriceByHour(xs) {
    const grouped =  xs.reduce(function(rv, x) {
        const hour = Math.floor(x.utctime/3600);

      (rv[hour] = rv[hour] || []).push(x);
      return rv;
    }, {})

    var result = []
    Object.keys(grouped).forEach(k => result.push({estimated_price: grouped[k].reduce((p, vv) => p = p + vv.estimated_price/grouped[k].length, 0), utctime: 3600 * k}))

    return result
  };

export function IntradayPricesChart({type}) {
    const bd = useData();
    const theme = useTheme();

    const name = "order_book_" + (type !== undefined ? type : "qtr")
    const estimated_prices = type === undefined || type === "qtr"? bd["price_data"] : groupEstimatedPriceByHour(bd["price_data"])

    const data = {
        labels: bd[name].map(el => new Date(1000*el.utctime)),
        datasets: [
        {
            fill: true,
            label: 'ASK QTR',
            data: bd[name].map(el => el["1mw"] === "nan" ? null : el["1mw"]),
            backgroundColor:  theme.palette.secondary.main,//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            stack: 'Stack 0',
            order: 2
        },
        {
            fill: true,
            label: 'BID QTR',
            data: bd[name].map(el => el["-1mw"] === "nan" ? null : el["-1mw"]),
            backgroundColor:  theme.palette.primary.main,//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            stack: 'Stack 0',
            order: 1
        },
        {
            type: 'line',
            fill: false,
            label: 'Estimated Price',
            data: estimated_prices.map(el => ({x: new Date(1000*el["utctime"]), y: el["estimated_price"] === "nan" ? null : el["estimated_price"]})),
            borderWidth: 2,
            borderColor: theme.palette.text.primary,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 0
        },
        /*{
            fill: true,
            label: 'ASK HR',
            data: bd["order_book_hr"].map(el => {return {
                x: new Date(1000*el.utctime), 
                y: el["1mw"] === "nan" ? null : el["1mw"]
            }}),
            backgroundColor:  theme.palette.primary.main,//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            stack: 'Stack 1',
        },
        {
            fill: true,
            label: 'BID HR',
            data: bd["order_book_hr"].map(el => {return {
                x: new Date(1000*el.utctime), 
                y: el["-1mw"] === "nan" ? null : el["-1mw"]
            }}),
            backgroundColor: theme.palette.secondary.main,//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            stack: 'Stack 1',
        },*/
        ],
    };

    const options = getBarTimeChartOptions();
    options["scales"]["y"]["stacked"] = false;

    if (type === undefined || type == "qtr") {
        options["scales"]["y"]["min"] = -400
        options["scales"]["y"]["max"] = 400
    }

    return <BarTimeChart data={data} options={options} />
}

export function AlphaChart({type}) {
    const bd = useData();
    const theme = useTheme();

    const running_cost_long = bd.overview["running_cost_long_" + type];
    const running_cost_short = bd.overview["running_cost_short_" + type];

    const data = {
        labels: running_cost_long["x"],
        datasets: [
        {
            type: 'line',
            fill: false,
            backgroundColor: theme.palette.primary.main,
            label: 'Long',
            data: running_cost_long["y"].map(el => -el),
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 0
        },
        {
            type: 'line',
            fill: false,
            backgroundColor: theme.palette.secondary.main,
            label: 'Short',
            data: running_cost_short["y"].map(el => -el),
            borderWidth: 2,
            borderColor: theme.palette.secondary.main,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 0
        },
        ],
    };

    return <Line data={data} width={"100%"} options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
              position: 'bottom',
              display: true,
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: (idx) => (Math.round(100*running_cost_long["x"][idx])/100).toString()
                },
            },
            y: {
                grid: {
                    //lineWidth: (context) => context.tick.value == 0 ? 2 : 1,
                    color: (context) => context.tick.value == 0 ? "black" : "lightgrey",
                }
            }
        }
    }} />
}

export function ImbalancePricesChart() {
    const bd = useData();
    const theme = useTheme();

    const data = {
        labels: bd["price_data"].map(el => new Date(1000*el.utctime)),
        datasets: [
        {
            fill: true,
            label: 'Imbalance Price',
            data: bd["price_data"].map(el => el["imb_price"] === "nan" ? null : el["imb_price"]),
            backgroundColor:  theme.palette.primary.main,//'rgba(255,255,255,0.4)',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 2
        },
        {
            type: 'line',
            fill: false,
            label: 'Epex Price',
            data: bd["price_data"].map(el => el["epex"] === "nan" ? null : el["epex"]),
            borderWidth: 2,
            borderColor: theme.palette.secondary.main,
            backgroundColor:  alphaColor(theme.palette.secondary.main, 0.2),
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            order: 1
        },
        ],
    };

    return <BarTimeChart data={data} />
}

function toArray(rgb) {
    const r = rgb >> 16;
    const g = (rgb >> 8) % 256;
    const b = rgb % 256;
  
    return [r, g, b];
  }

function colorInterpolate(col1, col2, p) {
    const rgb1 = parseInt(col1.substring(1,7), 16);
    const rgb2 = parseInt(col2.substring(1,7), 16);
  
    const [r1, g1, b1] = toArray(rgb1);
    const [r2, g2, b2] = toArray(rgb2);
  
    const q = 1-p;
    const rr = Math.round(r1 * p + r2 * q);
    const rg = Math.round(g1 * p + g2 * q);
    const rb = Math.round(b1 * p + b2 * q);
  
    return "#" + Number((rr << 16) + (rg << 8) + rb).toString(16);
  }

const colorArray = [...Array(100).keys()].map(el => () => "hsl(" + (55 * el) % 360 + ',70%,70%)')

export function InputContributionsChart({debug}) {
    const bd = useData();
    const theme = useTheme();

    // for consistency in colors
    const ds = debug ? Object.keys(bd["input_contributions"][0]) : ["solar", "wind", "load", "netborder", "xbid", "price", "bids", "si", "netpos", "other"]

    const data = {
        labels: bd["input_contributions"].map(el => new Date(1000*el.utctime)),
        datasets: 
            ds.filter((value) => value != "utctime").map((name, index, ar) => ({
                fill: true,
                label: name.replace('_', ' ').toUpperCase(),
                data: bd["input_contributions"].map(el => el[name] === "nan" ? null : el[name]),
                backgroundColor:  colorArray[index],//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            }))
        ,
    };

    const options = getBarTimeChartOptions();
    options["interaction"]["mode"] = 'point';
    options["interaction"]["intersect"] = true;
    options["plugins"]["legend"]["display"] = !debug;

    return <BarTimeChart data={data} options={options} />
}

export function InputValuesChart({input}) {
    const bd = useData();
    const theme = useTheme();

    const data = {
        labels: bd["input_values"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: true,
                label: input,
                data: bd["input_values"].map(el => el[input] === "nan" ? null : el[input]),
                backgroundColor:  colorArray[0],//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            }]
        ,
    };

    const options = getBarTimeChartOptions();
    options["interaction"]["mode"] = 'point';
    options["interaction"]["intersect"] = true;
    options["plugins"]["legend"]["display"] = false;

    return <BarTimeChart data={data} options={options} />
}

export function ProbabilitiesChart() {
    const bd = useData();
    const theme = useTheme();

    const data = {
        labels: bd["price_data"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: true,
                label: "Positive Imbalance Probability",
                data: bd["price_data"].map(el => el["positive_probability"] === "nan" ? null : el["positive_probability"]),
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor:  alphaColor(theme.palette.primary.main, 0.6),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            }]
        ,
    };

    const options = getBarTimeChartOptions();
    options["interaction"]["mode"] = 'nearest';
    options["interaction"]["intersect"] = false;

    return <TimeChart data={data} options={options} />
}

export function ForecastChart() {
    const bd = useData();
    const theme = useTheme();

    const alpha1 = 0.3;
    const alpha2 = 0.5;

    const data = {
        labels: bd["forecast"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: false,
                label: "Estimated Price",
                data: bd["forecast"].map(el => el["estimated_price"] === "nan" ? null : el["estimated_price"]),
                borderWidth: 2,
                borderColor: '#555555',
                backgroundColor:  alphaColor('#555555', 1),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: true,
                order: -1
            },
            {
                fill: false,
                label: "Mid Price",
                data: bd["forecast"].map(el => el["mid_price"] === "nan" ? null : el["mid_price"]),
                borderWidth: 2,
                borderColor: '#eba434',
                backgroundColor:  'transparent',//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                borderDash: [6,6],
                order: -1
            },
            {
                fill: false,
                label: "Estimated MDP",
                data: bd["forecast"].map(el => el["mdp_estimate"] === "nan" ? null : el["mdp_estimate"]),
                borderWidth: 2,
                borderColor: 'blue',
                backgroundColor:  'transparent',//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                borderDash: [6,6],
                order: 0
            },
            {
                fill: false,
                label: "Estimated MIP",
                data: bd["forecast"].map(el => el["mip_estimate"] === "nan" ? null : el["mip_estimate"]),
                borderWidth: 2,
                borderColor: 'red',
                backgroundColor:  'transparent',//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                borderDash: [6,6],
                order: 0
            },
            {
                fill: '+1',
                label: "MIP 0.05",
                data: bd["forecast"].map(el => el["mip_050"] === "nan" ? null : el["mip_050"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#FF0000', alpha1),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '+1',
                label: "MIP 0.25",
                data: bd["forecast"].map(el => el["mip_250"] === "nan" ? null : el["mip_250"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#FF0000', alpha2),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: false,
                label: "MIP 0.75",
                data: bd["forecast"].map(el => el["mip_750"] === "nan" ? null : el["mip_750"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#FF0000', alpha2),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '-1',
                label: "MIP 0.95",
                data: bd["forecast"].map(el => el["mip_950"] === "nan" ? null : el["mip_950"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#FF0000', alpha1),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '+1',
                label: "MDP 0.05",
                data: bd["forecast"].map(el => el["mdp_050"] === "nan" ? null : el["mdp_050"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#0000FF', alpha1),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '+1',
                label: "MDP 0.25",
                data: bd["forecast"].map(el => el["mdp_250"] === "nan" ? null : el["mdp_250"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#0000FF', alpha2),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: false,
                label: "MDP 0.75",
                data: bd["forecast"].map(el => el["mdp_750"] === "nan" ? null : el["mdp_750"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#0000FF', alpha2),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '-1',
                label: "MDP 0.95",
                data: bd["forecast"].map(el => el["mdp_950"] === "nan" ? null : el["mdp_950"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#0000FF', alpha1),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            
        ]
        ,
    };

    const options = getTimeChartOptions();
    options["interaction"]["mode"] = 'nearest';
    options["interaction"]["intersect"] = false;
    options["plugins"]["legend"] = {
        labels: {
            filter: function(legendItem, data) {
            let label = data.datasets[legendItem.datasetIndex].label || '';
            if (typeof(label) !== 'undefined') {
                if (legendItem.datasetIndex >= 3){
                    return false;
                }
            }
            return label;
            }
        },
        position: "bottom",
    }

    return <TimeChart data={data} options={options} />
}

export function ForecastedProbabilitiesChart() {
    const bd = useData();
    const theme = useTheme();

    const alpha1 = 0.3;
    const alpha2 = 0.5;

    const data = {
        labels: bd["forecast"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: false,
                label: "Positive Probability",
                data: bd["forecast"].map(el => el["positive_probability"] === "nan" ? null : el["positive_probability"]),
                borderWidth: 2,
                borderColor: '#555555',
                backgroundColor:  alphaColor('#555555', 1),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: true,
                order: -1
            },
            {
                fill: '+1',
                label: "P100D",
                data: bd["forecast"].map(el => el["p100d"] === "nan" ? null : el["p100d"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor(theme.palette.primary.main, alpha1),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '+1',
                label: "P50D",
                data: bd["forecast"].map(el => el["p50d"] === "nan" ? null : el["p50d"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor(theme.palette.primary.main, alpha2),
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: false,
                label: "P50I",
                data: bd["forecast"].map(el => el["p50i"] === "nan" ? null : el["p50i"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor(theme.palette.primary.main, alpha2),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            },
            {
                fill: '-1',
                label: "P100I",
                data: bd["forecast"].map(el => el["p100i"] === "nan" ? null : el["p100i"]),
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor:  alphaColor(theme.palette.primary.main, alpha1),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: 0
            }
        ]
        ,
    };

    const options = getTimeChartOptions();
    options["interaction"]["mode"] = 'nearest';
    options["interaction"]["intersect"] = false;
    options["plugins"]["legend"] = {
        labels: {
            filter: function(legendItem, data) {
            let label = data.datasets[legendItem.datasetIndex].label || '';
            if (typeof(label) !== 'undefined') {
                if (legendItem.datasetIndex >= 1){
                    return false;
                }
            }
            return label;
            }
        },
        position: "bottom",
    }

    return <TimeChart data={data} options={options} />
}

export function ForecastedSIPChart() {
    const bd = useData();
    const theme = useTheme();

    const data = {
        labels: bd["forecast"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: true,
                label: "System Imbalance Prediction",
                data: bd["forecast"].map(el => el["sip"] === "nan" ? null : el["sip"]),
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor:  alphaColor(theme.palette.primary.main, 0.5),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: true,
                order: -1
            },
        ]
        ,
    };

    const options = getTimeChartOptions();
    options["interaction"]["mode"] = 'nearest';
    options["interaction"]["intersect"] = false;
    options["plugins"]["legend"] = {
        labels: {
            filter: function(legendItem, data) {
            let label = data.datasets[legendItem.datasetIndex].label || '';
            if (typeof(label) !== 'undefined') {
                if (legendItem.datasetIndex >= 1){
                    return false;
                }
            }
            return label;
            }
        },
        position: "bottom",
    }

    return <TimeChart data={data} options={options} />
}

export function CurrentSystemImbalanceChart() {
    const bd = useData();

    const theme = useTheme();

    const data = {
        labels: bd["imb_data"].map(el => new Date(1000*el.utctime)),
        datasets: 
            [{
                fill: false,
                label: "System Imbalance",
                data: bd["imb_data"].map(el => el["current_si"] === "nan" ? null : el["current_si"]),
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor:  theme.palette.primary.main,//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: -1
            },
            {
                fill: false,
                label: "NRV",
                data: bd["imb_data"].map(el => el["current_nrv"] === "nan" ? null : el["current_nrv"]),
                borderWidth: 2,
                borderColor: '#a83268',
                backgroundColor:  '#a83268',//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: -1
            },
            {
                fill: true,
                label: "mFRR",
                data: bd["imb_data"].map(el => el["mfrr"] === "nan" ? null : el["mfrr"]),
                borderWidth: 2,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#0000FF', 0.3),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: -1,
            },
            {
                fill: true,
                label: "aFRR",
                data: bd["imb_data"].map(el => el["afrr"] === "nan" ? null : el["afrr"]),
                borderWidth: 2,
                borderColor: 'transparent',
                backgroundColor:  alphaColor('#FF0000', 0.3),//colorInterpolate(theme.palette.primary.main, theme.palette.tertiary.main, index/ar.length),//'rgba(255,255,255,0.4)',
                cubicInterpolationMode: 'monotone',
                pointStyle: false,
                order: -1,
            },
        ]
        ,
    };

    const options = getTimeChartOptions();
    options["scales"]["x"]["time"]["unit"] = "minute"
    options["scales"]["x"]["max"] = new Date(1000*Math.ceil(Math.max(...bd["imb_data"].map(el => el.utctime+1))/900)*900)
    console.log(Math.max(...bd["imb_data"].map(el => el.utctime)))
    options["scales"]["x"]["ticks"] = {stepSize: 15, offset: 1}
    options["plugins"]["legend"]["display"] = false;

    return <TimeChart data={data} options={options} />
}