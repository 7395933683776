import * as React from 'react'
import { Box, Typography, Button, Switch } from "@mui/material";
import { authfetch } from "../login/login_utils";
import constants from '../../scripts/constants'
import WebPushService from "../../scripts/webpushservice";


export default function SettingsPage() {
    const [push, setPush] = React.useState(false);

    React.useEffect(() => {WebPushService.getSubscription().then((result) => setPush(result !== null))}, []);
    

    const handlePushChange = async (e) => {
        const { checked } = e.target;
        setPush(checked);

        try {
        if (!checked) {
            const payload = await WebPushService.unsubscribe();
            console.log("Unsubscribed from web push");
            return;
        }

        if (!WebPushService.hasPermission()) {
            await WebPushService.requestPermission();
        }
        let subscription = await WebPushService.getSubscription();
        if (!subscription) {
            subscription = await WebPushService.subscribe();
        }
        console.log("Subscribed to web push");
        } catch (error) {
        setPush(!checked);
        console.error(error);
        }
    };

    return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Switch
                checked={push}
                onChange={handlePushChange}
            />
        </Box>
}