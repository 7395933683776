import constants from '../../scripts/constants'

const lstr = localStorage.getItem("login")
var login = lstr ? JSON.parse(lstr) : {}
var listener = null;

export function getLogin() {
    return login;
}

export function setLogin(lc) {
    localStorage.setItem('login', JSON.stringify(lc))
    login = lc;
    listener(lc);
}

export function getLoginStatus() {
    if (!login || !login.expiry) {
        return 0;
    }

    if (login.expiry >= (new Date()).getTime()/1000) {
        return 1; // logged in and access token valid
    }

    if (login.expiry_refresh && login.expiry_refresh >= (new Date()).getTime()/1000) {
        return 2; // logged in, but has to be refreshed
    }

    return 0;
}

export async function refresh() {
    const res = await fetch(
        constants["HOST"] + "/api/refresh",
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + login.refresh_token },
            body: JSON.stringify({}),
            credentials: 'include'
        })

    if (res.status == 200) {
        const content = await res.json();

        login["access_token"] = content["access_token"]
        login["expiry"] = content["expiry"]
    } else {
        setLogin({})
    }
}

export async function authfetch(url, args) {
    if (getLoginStatus() == 2) { // refresh
        await refresh()
    }

    if (args === undefined) {
        args = {}
    }

    if ('headers' in args) {
        args.headers["Authorization"] = 'Bearer ' + login.access_token
    } else {
        args["headers"] = {'Authorization': 'Bearer ' + login.access_token}
    }

    const res = await fetch(url, args)

    if (res.status == 401) { // unauthorized
        //window.location = "/login";
        return res;
    }

    if (res.headers.get('Access-Token')) {
        login["access_token"] = res.headers.get('Access-Token')
        login["expiry"] = res.headers.get('Access-Token-Expiry')
    }

    return res;
}

export function setListener(l) {
    listener = l
}

export async function tryLogin(username, password, keepLoggedIn) {
    
    try {
        const f = await fetch(
            constants["HOST"] + "/api/login",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: username, password: password, keepLoggedIn: keepLoggedIn ? 1 : 0 }),
                credentials: 'include'
            })

        if (!f.status == 200) {
            return false;
        }

        const d = await f.json();

        if (!d.success == 1) {
            return false;
        }

        setLogin(d);

        return true;
    } catch (e) {
        console.error(e)
    }

    return false;
}

export async function tryLogout() {
    setLogin({})

    return true;
}